import React from 'react';
import { Control, useController } from 'react-hook-form';
import { AppSignatureExtended, AppSignaturePad } from './AppSignaturePad';

type AppSignaturePadRHFProps = {
  name: string;
  control: Control<any>; // Le type 'any' peut être remplacé par le type du formulaire
  minPoints?: number;
  required?: boolean;
  className?: string;
  padClassName?: string;
};

export const AppSignaturePadRHF = ({
  name,
  control,
  minPoints = 10,
  required,
  className,
  padClassName,
}: AppSignaturePadRHFProps) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required,
      validate: (signature: AppSignatureExtended) =>
        signature.isValid || 'Signature invalide. Veuillez signer.',
    },
  });

  return (
    <div className={className}>
      <AppSignaturePad
        className={className}
        padClassName={padClassName}
        minPoints={minPoints}
        initialData={value?.data} // Passez la valeur actuelle à AppSignaturePad
        onChange={(signature: AppSignatureExtended) => {
          if (signature?.isValid) {
            console.log('xxx signature valide');
            // const svg = signaturePad.toSVG();
            // const dataURL = signaturePad.toDataURL('image/jpeg'); // save image as JPEG
          } else {
            console.log('xxx signature non valide');
          }
          onChange(signature);
        }}
      />
      {error && <p className="text-red-500">{error.message}</p>}
    </div>
  );
};
