import React, { useMemo } from 'react';
import {
  LoadableContentActionStatus,
  LoadableContentState,
} from 'src/business/_core/data/app-loading';
import {
  AppHeroIcons,
  AppPageContainerWithFixedBars,
  AppTabsBar,
  AppTabsBarTab,
} from 'src/business/_core/modules/layout';
import {
  DiveCenterBookingPageTab2BookingParticipants,
  DiveCenterBookingPageTab3BookingSessionsFuture,
  DiveCenterBookingPageTab4BookingSessionsPast,
  DiveCenterBookingPageTabId,
} from './tabs';
import { DiveCenterBookingPageTab1BookingResume } from './tabs/DiveCenterBookingPageTab1BookingResume/DiveCenterBookingPageTab1BookingResume';
import { DiveCenterBookingPageTab5Inquiries } from './tabs/DiveCenterBookingPageTab5Inquiries';
import { DiveCenterBookingPageLocalState } from './useDiveCenterBookingPageLocalState.hook';

export const DiveCenterBookingPageViewTabs = ({
  localState,
  contentState,
  lastActionStatus,
}: {
  localState: DiveCenterBookingPageLocalState;
  contentState?: LoadableContentState;
  lastActionStatus?: LoadableContentActionStatus;
}) => {
  const { currentTab, setCurrentTab, aggregatedBookingResume, clubResume } =
    localState;
  const bookingStatus = aggregatedBookingResume?.booking?.bookingStatus;
  const DISABLE_INQUIRIES = true;
  const allInquiries = DISABLE_INQUIRIES ? [] : clubResume?.inquiries;

  const customerSpaceSettings =
    localState?.clubSettings?.publicSettings?.customerSpace;
  const tabs = useMemo(() => {
    const tabs: AppTabsBarTab<DiveCenterBookingPageTabId>[] = [
      {
        id: 'booking-resume',
        label: 'Résa',
        labelShort: 'Réservation',
        urlSuffix: '',
        icon: <AppHeroIcons.booking className="w-6 h-6" />,
      },
    ];
    if (allInquiries.length > 0) {
      tabs.push({
        id: 'booking-inquiries',
        label: 'Formulaires',
        labelShort: 'Formulaires',
        urlSuffix: '',
        icon: <AppHeroIcons.actionEdit className="w-6 h-6" />,
      });
    }
    if (customerSpaceSettings?.display?.participants?.enabled) {
      tabs.push({
        id: 'booking-participants',
        label: 'Participants',
        labelShort: 'Participants',
        urlSuffix: '',
        icon: <AppHeroIcons.divers className="w-6 h-6" />,
      });
    }

    if (customerSpaceSettings?.display?.divesFuture?.enabled) {
      tabs.push({
        id: 'booking-sessions-future',
        label: 'Plongées',
        labelShort: 'Plongées',
        urlSuffix: '',
        icon: <AppHeroIcons.calendar className="w-6 h-6" />,
      });
    }

    if (
      bookingStatus !== 'draft' &&
      customerSpaceSettings?.display?.divesPast?.enabled
    ) {
      tabs.push({
        id: 'booking-sessions-past',
        label: 'Historique',
        labelShort: 'Historique',
        urlSuffix: '',
        icon: <AppHeroIcons.history className="w-6 h-6" />,
      });
    }

    return tabs;
  }, [
    allInquiries.length,
    bookingStatus,
    customerSpaceSettings?.display?.divesFuture?.enabled,
    customerSpaceSettings?.display?.divesPast?.enabled,
    customerSpaceSettings?.display?.participants?.enabled,
  ]);

  return (
    <AppPageContainerWithFixedBars
      contentState={contentState}
      lastActionStatus={lastActionStatus}
      marginBottom={true}
      paddingBottom={true}
      contentClassName="bg-gray-50 app-p-content"
      headerBar={() => (
        <AppTabsBar
          className="sm:px-4"
          theme="dive-center-booking"
          tabs={tabs}
          value={currentTab}
          onChange={(tab) => setCurrentTab(tab?.id)}
        />
      )}
      // footerBar={() => (
      //   <AppButtonsBar hasChanges={true}>
      //     {enableCustomerUpdate && (
      //       <>
      //         {hasAnyChange ? (
      //           <>
      //             <AppButton
      //               type="button"
      //               className="uppercase font-bold"
      //               // disabled={!formState.isValid}
      //               size="normal"
      //               icon={AppHeroIcons.actionSave}
      //               iconPosition="right"
      //               color="primary-outline-light"
      //               onClick={saveDraftChanges}
      //             >
      //               Terminer
      //             </AppButton>
      //             <input type="submit" className="hidden" />
      //           </>
      //         ) : (
      //           <AppButton
      //             type="button"
      //             className="uppercase font-bold"
      //             // disabled={!formState.isValid}
      //             size="normal"
      //             icon={AppHeroIcons.actionEdit}
      //             iconPosition="right"
      //             color="primary-outline-light"
      //             onClick={() => {
      //               if (currentTab === 'booking-participants') {
      //                 setMemberEditDialogState({
      //                   isOpen: true,
      //                   updateState,
      //                   bookingResume: initialBookingResume,
      //                   participantsIndex: 0,
      //                 });
      //                 setViewMode('checkout-edit-members');
      //               } else {
      //                 setCurrentTab('booking-participants');
      //               }
      //             }}
      //           >
      //             Commencer
      //           </AppButton>
      //         )}
      //       </>
      //     )}
      //   </AppButtonsBar>
      // )}
    >
      <div className="app-p-content">
        {currentTab === 'booking-resume' ? (
          <DiveCenterBookingPageTab1BookingResume localState={localState} />
        ) : currentTab === 'booking-inquiries' ? (
          <DiveCenterBookingPageTab5Inquiries localState={localState} />
        ) : currentTab === 'booking-participants' ? (
          <DiveCenterBookingPageTab2BookingParticipants
            localState={localState}
            onClickMember={undefined}
            // onClickMember={(
            //   bookingMembersFull,
            //   { action, participantsIndex },
            // ) => {
            //   if (action === 'edit' && enableCustomerUpdate) {
            //     actions.editParticipantByIndex(participantsIndex);
            //   }
            // }}
          />
        ) : currentTab === 'booking-sessions-future' ? (
          <DiveCenterBookingPageTab3BookingSessionsFuture
            localState={localState}
          />
        ) : currentTab === 'booking-sessions-past' ? (
          <DiveCenterBookingPageTab4BookingSessionsPast
            localState={localState}
          />
        ) : null}
      </div>
    </AppPageContainerWithFixedBars>
  );
};
