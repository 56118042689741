import {
  AppInquirySettings,
  Customer_ClubSettingsPublicCustomer,
  buildDefaultRGPDAgreementMessage,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';

export type GDPRConsentConfig = {
  gdprConsentEnabled: boolean;
  gdprConsentAgreementId: string;
  gdprConsentMessage: string;
  gdprConsentMessageDescription: string;
};

export function useGDPRConsentConfig(
  clubSettings: Customer_ClubSettingsPublicCustomer,
  {
    inquirySettings,
  }: {
    inquirySettings?: AppInquirySettings;
  },
): GDPRConsentConfig {
  const contact = clubSettings?.publicData?.contact;
  const brand = clubSettings?.publicData?.brand;
  const globalGdprSettings =
    clubSettings?.publicSettings?.customerSpace?.consents?.gdpr;

  const gdprEnabled = inquirySettings
    ? inquirySettings?.consents?.gdpr?.enabled
    : globalGdprSettings?.enabled;

  const brandFullName = useMemo(
    () => `${brand?.name}, ${brand?.locationResume}`,
    [brand?.locationResume, brand?.name],
  );

  return useMemo(() => {
    if (gdprEnabled) {
      const gdprConsentMessageDescription =
        globalGdprSettings?.customize &&
        globalGdprSettings?.consentAgreement?.message?.trim()?.length > 0
          ? globalGdprSettings?.consentAgreement?.message
          : buildDefaultRGPDAgreementMessage({
              brandFullName,
              contactEmailAddress: contact?.emailAddress,
            });
      const gdprConsentMessage =
        'Je reconnais avoir pris connaissance des informations sur le RGPD.';
      return {
        gdprConsentEnabled: true,
        gdprConsentMessageDescription,
        gdprConsentMessage,
        gdprConsentAgreementId: globalGdprSettings?.consentAgreement?._id,
      };
    }
    const gDPRConsentConfig: GDPRConsentConfig = {
      gdprConsentEnabled: false,
      gdprConsentMessageDescription: undefined,
      gdprConsentMessage: undefined,
      gdprConsentAgreementId: undefined,
    };
    return gDPRConsentConfig;
  }, [
    brandFullName,
    contact?.emailAddress,
    gdprEnabled,
    globalGdprSettings?.consentAgreement?._id,
    globalGdprSettings?.consentAgreement?.message,
    globalGdprSettings?.customize,
  ]);
}
