import React, { useEffect } from 'react';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { useAppSecurityUser } from 'src/business/auth/services';
import { DiveCenterBookingInquiryResponsePage_ConfirmFormPanel } from './DiveCenterBookingInquiryResponsePage_ConfirmFormPanel';
import { DiveCenterBookingInquiryResponsePage_FillFormPanel } from './DiveCenterBookingInquiryResponsePage_FillFormPanel';
import {
  DiveCenterBookingInquiryResponsePageLocalState,
  useDiveCenterBookingInquiryResponsePageLocalState,
} from './useDiveCenterBookingInquiryResponsePageLocalState.hook';

export const DiveCenterBookingInquiryResponsePage = ({
  navigationContext,
}: {
  navigationContext: {
    clubPublicReference: string;
    bookingId: string;
  };
}) => {
  const query = new URLSearchParams(window.location.search);
  const inquiryId = query.get('inquiryId');
  const inquiryResponseId = query.get('inquiryResponseId');
  const bookingMemberId = query.get('bookingMemberId');
  const diverId = query.get('diverId');

  const securityUser = useAppSecurityUser();

  const bookingId = navigationContext?.bookingId;
  // const diverId = securityUser.diver?.diverId;

  const localState: DiveCenterBookingInquiryResponsePageLocalState =
    useDiveCenterBookingInquiryResponsePageLocalState({
      bookingId,
      diverId,
      inquiryId,
      inquiryResponseId,
      bookingMemberId,
    });

  const {
    state: { form, viewState, setViewState, clubResume },
    data: {
      loadableContent,
      aggregatedDataCore,
      aggregatedBookingResume,
      inquiry,
    },
  } = localState;

  const clubPublicSettings = clubResume?.clubSettings?.publicSettings;

  useEffect(() => {
    if (
      loadableContent.lastActionStatus === 'success' &&
      loadableContent.contentState === 'full'
    ) {
      if (!aggregatedBookingResume) {
        appLogger.warn(
          '[DiveCenterBookingCheckoutPage] bookingResume not found, redirect back',
          aggregatedDataCore,
        );
        // TODO redirectTo(backUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadableContent.contentState, loadableContent.lastActionStatus]);

  return aggregatedBookingResume ? (
    <>
      {inquiry && viewState.mode === 'fill-form' && (
        <DiveCenterBookingInquiryResponsePage_FillFormPanel
          localState={localState}
        />
      )}
      {inquiry && viewState.mode === 'confirm-form' && (
        <DiveCenterBookingInquiryResponsePage_ConfirmFormPanel
          localState={localState}
        />
      )}
    </>
  ) : null;
};
