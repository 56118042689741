import {
  AppInquiryForClubResumeGql_Customer,
  AppInquiryResponseForBookingGql_Customer,
  Customer_ClubResumePublic,
  CustomerInquiryWithResponse,
  DiveMode,
  INQUIRY_RESPONSE_EDITABLE_STATUSES,
} from '@mabadive/app-common-model';
import {
  appInquiryFilter,
  dataSorter,
  dateService,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { CUS_BookingMemberFull } from '../../../../../club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { DiveCenterBookingPageAggregatedBookingResume } from '../../model';

export type CuApp_MemberInquiriesDetails = {
  bookingMemberFull: CUS_BookingMemberFull;
  activeInquiries: AppInquiryForClubResumeGql_Customer[];
  inquiriesWithResponse: CustomerInquiryWithResponse[];
  nextDiveModes: DiveMode[];
};

export const cuAppInquiriesByMemberBuilder = {
  buildInquiriesByMemberModel,
};
function buildInquiriesByMemberModel({
  aggregatedBookingResume,
  clubResume,
}: {
  aggregatedBookingResume: DiveCenterBookingPageAggregatedBookingResume;
  clubResume: Customer_ClubResumePublic;
}): CuApp_MemberInquiriesDetails[] {
  if (!aggregatedBookingResume || !clubResume) {
    return []; // data not loaded
  }
  const { bookingMembersFull, bookingParticipantsFull } =
    aggregatedBookingResume;
  return bookingMembersFull.map((bookingMemberFull) => {
    const diver = bookingMemberFull.diver;
    const memberBookingParticipantsFull = bookingParticipantsFull.filter(
      (bpf) => bpf.diver?._id === diver._id,
    );
    // only next
    const nextMemberBookingParticipantsFull =
      memberBookingParticipantsFull?.filter(
        (bpf) =>
          dateService.isTodayUTC(bpf?.diveSession?.time) ||
          dateService.isFutureDayUTC(bpf?.diveSession?.time),
      );

    const diveModes = nextMemberBookingParticipantsFull?.reduce((acc, bpf) => {
      if (!acc.includes(bpf?.diveSessionParticipant?.diveMode)) {
        acc.push(bpf?.diveSessionParticipant?.diveMode);
      }
      return acc;
    }, [] as DiveMode[]);
    const nextDiveModes = [...new Set(diveModes)];
    const enabledInquiries = (clubResume.inquiries ?? []).filter(
      (inquiry) => inquiry.settings?.enabled,
    );
    const activeInquiries = appInquiryFilter.findActiveInquiries(
      enabledInquiries,
      {
        nextDiveModes,
        diver,
      },
    );

    const inquiriesWithResponse: CustomerInquiryWithResponse[] = useMemo(() => {
      // only booking member diver inquiries responses
      const bmInquiryResponses =
        aggregatedBookingResume?.bookingInquiryResponses.filter(
          (x) => x.diverId === bookingMemberFull?.diver?._id,
        );

      return activeInquiries
        .map((inquiry) => {
          const matchingInquiryResponses: AppInquiryResponseForBookingGql_Customer[] =
            (bmInquiryResponses ?? []).filter(
              (x) => x.inquiryId === inquiry._id,
            );
          const responses = dataSorter.sortMultiple(matchingInquiryResponses, {
            getSortAttributes: (response) => {
              return [
                {
                  // les réponses éditables en premier
                  value: INQUIRY_RESPONSE_EDITABLE_STATUSES.includes(
                    response?.status,
                  )
                    ? 1
                    : 2,
                },
                {
                  // puis par autre statut
                  value:
                    response?.status === 'draft'
                      ? 1
                      : response?.status === 'rejected'
                      ? 2
                      : response?.status === 'submitted'
                      ? 3
                      : 4,
                },
                {
                  // puis par date
                  value: response?._createdAt,
                },
              ];
            },
          });
          const lastResponse = responses?.[0];
          return {
            inquiry,
            responses,
            lastResponse,
          };
        })
        .filter((x) => x.lastResponse?.status !== 'validated'); // le questionnaire est masqué une fois validé
    }, [activeInquiries, bookingMemberFull?.diver?._id]);

    const details: CuApp_MemberInquiriesDetails = {
      bookingMemberFull,
      activeInquiries,
      nextDiveModes,
      inquiriesWithResponse,
    };
    return details;
  });
}
